import TextInput from "../TextInput";
import AsyncSelectBox from "../AsyncSelectBox";
import { CURRENCIES } from "../../../constants";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 6px;
`;

const DEFAULT_CURRENCIES = CURRENCIES.filter((i) =>
  ["USD", "EUR", "GBP", "TRY"].includes(i.code)
);

export default function MoneyInput({
  price = null,
  currency = null,
  onChange,
  disabled = false,
  ...props
}) {
  const loadCurrencies = async (inputValue, callback) => {
    const filtered = CURRENCIES.filter(
      (i) => i.code.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
    callback(filtered);
  };

  return (
    <Container>
      <TextInput
        type="number"
        value={price}
        onChange={(event) =>
          onChange("price", parseInt(event.target.value, 10) || "")
        }
        disabled={disabled}
        {...props}
      />
      <AsyncSelectBox
        value={currency}
        defaultOptions={DEFAULT_CURRENCIES}
        loadOptions={loadCurrencies}
        getOptionLabel={(option) => option.code}
        getOptionValue={(option) => option.code}
        isSearchable={true}
        isClearable={false}
        onChange={(value) => onChange("currency", value)}
        isDisabled={disabled}
      />
    </Container>
  );
}
