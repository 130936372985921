import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import filtersReducer from "./slices/filters";
import listReducer from "./slices/list";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    filters: filtersReducer,
    list: listReducer,
  },
});
