import React from "react";
import styled from "styled-components";
import green1 from "./green-1.svg";
import green2 from "./green-2.svg";

const Container = styled.div`
  padding: 50px 0px;

  @media only screen and (max-width: 768px) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

const Green = styled.span`
  color: #247c66;
`;

const Row = styled.div`
  margin-bottom: 100px;
  padding: 0px 30px;
  display: flex;
  gap: 100px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Content = styled.div``;

const H4 = styled.h4`
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: #253745;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin: 40px 0px 30px 0px;
`;

const Quote = styled.p`
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: rgba(37, 55, 69, 0.7);
  margin: 0px 0px 40px 0px;
  max-width: 560px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Button = styled.a`
  background: #253745;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  text-decoration: none;
  padding: 12px 30px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  color: #f9fbfd;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
  }
`;

const Image = styled.img`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const NewLine = styled.br`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default function GreenNewsSection() {
  return (
    <Container>
      <Row>
        <Image src={green1} height="360" alt="Green news article" />
        <Content>
          <H4>
            <Green>Elektronik Atıkların</Green> <NewLine />
            Küresel Maliyeti
          </H4>
          <Quote>
            “The increase in consumption of electronics has two major adverse
            ecological effects. First, it significantly increases mining and
            procurement for the materials needed for production of gadgets. And
            second, discarded devices produce large quantities of electronic
            waste.”
          </Quote>
          <Button
            href="https://www.theatlantic.com/technology/archive/2016/09/the-global-cost-of-electronic-waste/502019/"
            target="_blank"
            rel="noreferrer noopenner"
          >
            Makaleyi incele
          </Button>
        </Content>
      </Row>
      <Row>
        <Content>
          <H4>
            <Green>İkinci el</Green> alışveriş <Green>dünyayı</Green> <br />
            nasıl kurtarabilir?
          </H4>
          <Quote>
            “Second-hand shopping lowers the impact of the things we consume as
            we’re reusing things that have already been produced and we are
            diverting them from landfills.”
          </Quote>
          <Button
            href="https://greenwithless.com/second-hand-shopping-planet/"
            target="_blank"
            rel="noreferrer noopenner"
          >
            Makaleyi incele
          </Button>
        </Content>
        <Image src={green2} height="360" alt="Green news article" />
      </Row>
    </Container>
  );
}
