import styled from "styled-components";
import Product from "../../../components/Product";

const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export default function Posts({ items }) {
  return (
    <>
      {items.length > 0 && (
        <Container>
          {items.map((item, index) => (
            <Product key={index} {...item} />
          ))}
        </Container>
      )}
    </>
  );
}
