import { createSlice } from "@reduxjs/toolkit";

const query = new URLSearchParams(window.location.search);

const initialState = {
  page: parseInt(query.get("page"), 10) || 1,
  per_page: parseInt(query.get("per_page"), 10) || 12,
  search_text: query.get("search_text") || "",
  category_id: parseInt(query.get("category_id"), 10) || null,
  place_id:
    parseInt(query.get("place_id"), 10) || (window.isTurkishHost ? 225 : null),
};

export const filtersSclie = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    clear: (state) => {
      state.isLogged = false;
    },
  },
});

export const { setFilters, clear } = filtersSclie.actions;

export default filtersSclie.reducer;
