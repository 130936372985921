import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #247c66;
  margin-left: -100%;
  margin-right: -100%;
  display: flex;
  justify-content: center;
  color: #f9fbfd;

  @media only screen and (max-width: 768px) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 70px 0px 90px 0px;
`;

const H4 = styled.h4`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  text-align: center;
  margin: 0px;
`;

const Description = styled.p`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    padding: 0px 5px;
  }
`;

const Boxes = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const WhiteBoxContainer = styled.div`
  background: #f9fbfd;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 345px;
  padding: 30px 0px;

  width: calc(100% - 10px);

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const BoxValue = styled.div`
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  color: #247c66;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

const BoxTitle = styled.div`
  margin-top: 15px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: rgba(37, 55, 69, 0.8);
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

const WhiteBox = ({ title, value }) => {
  return (
    <WhiteBoxContainer>
      <BoxValue>{value}</BoxValue>
      <BoxTitle>{title}</BoxTitle>
    </WhiteBoxContainer>
  );
};

export default function StatsSection() {
  return (
    <Container>
      <Content>
        <H4>İstatistikler</H4>
        <Description>Şimdiye kadar yaşadıklarımızın kısa bir özeti</Description>
        <Boxes>
          <WhiteBox title="KULLANICI" value="+2k" />
          <WhiteBox title="İLAN" value="+700" />
          <WhiteBox title="GÖRÜNTÜLEME" value="+5k" />
        </Boxes>
      </Content>
    </Container>
  );
}
