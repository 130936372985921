import React from "react";
import styled from "styled-components";
import logo from "./logo.svg";

const Container = styled.div`
  background: #253745;
  margin-left: -100%;
  margin-right: -100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 20px);
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 40px 0px 100px 0px;

  @media only screen and (max-width: 768px) {
    width: unset;
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h4`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #f9fbfd;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
`;

const ExternalLink = styled.a`
  display: block;
  font-family: "Nunito";
  font-weight: 400;
  font-size: 16px;
  color: #e5e9ed;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  text-decoration: none;
`;

const LastLine = styled.div`
  padding: 20px 0px;
  display: flex;
  gap: 20px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    gap: 10px;
  }
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #f9fbfd;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const Section = styled.div`
  width: 30%;

  @media only screen and (max-width: 768px) {
    width: unset;
  }
`;

const LastSection = styled(Section)`
  width: 40%;

  @media only screen and (max-width: 768px) {
    width: unset;

    & img {
      height: 30px;
    }
  }
`;

export default function Footer() {
  return (
    <Container>
      <Content>
        <Columns>
          <LastSection>
            <LastLine>
              <img src={logo} alt="Logo" />
              <Name>
                ikinciel<span style={{ color: "#26BC96" }}>.dev</span>
              </Name>
            </LastLine>
          </LastSection>
          <Section>
            <Title>KAYNAKLAR</Title>
            <ExternalLink
              target="_blank"
              rel="noreferrer noopenner"
              href="https://circularcomputing.com/news/carbon-footprint-laptop"
            >
              Bir Dizüstü Bilgisayarın Karbon Ayak İzi
            </ExternalLink>
            <ExternalLink
              target="_blank"
              rel="noreferrer noopenner"
              href="https://www.theatlantic.com/technology/archive/2016/09/the-global-cost-of-electronic-waste/502019/"
            >
              Elektronik Atıkların Küresel Maliyeti
            </ExternalLink>
            <ExternalLink
              target="_blank"
              rel="noreferrer noopenner"
              href="https://www.carbonfootprint.com/"
            >
              Karbon Ayakizi
            </ExternalLink>
          </Section>
        </Columns>
      </Content>
    </Container>
  );
}
