import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Button from "../Button";
import LinkButton from "../Button";
import NavbarLink from "../NavbarLink";
import { clear } from "../../store/slices/auth";
import { GreenIcon } from "../Icons";
import MobileMenus from "./MobileMenus";
import { useState } from "react";
import GitHubLogo from "./github.png";

const GitHubButton = styled(LinkButton)`
  background-color: rgb(36, 41, 47);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  font-weight: 400;

  & img {
    height: 26px;
  }
`;

const StyledLink = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #253745;
  text-decoration: none;
  cursor: pointer;

  &.active {
    color: #247c66;
  }
`;

const Box = styled.div`
  position: fixed;
  top: 0px;
  z-index: 9999;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

const Container = styled.div`
  width: 1200px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 768px) {
    padding: 0px 10px;
    width: 100%;
  }
`;

const AppLink = styled(Link)`
  font-size: 16px;
  color: rgb(24, 24, 27);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    margin-top: -5px;
  }

  & img {
    height: 32px;
    border-radius: 50%;
  }

  @media only screen and (max-width: 768px) {
    gap: 2px;

    & img {
      display: none;
    }

    & svg {
      height: 24px;
    }
  }
`;

const SiteTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #247c66;
`;

const Domain = styled.span`
  color: #253745;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Navigations = styled.div`
  display: flex;
  gap: 30px;
  flex-grow: 4;
  justify-content: right;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const PageLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #253745;
  text-decoration: none;

  &.active {
    color: #247c66;
  }
`;

const ALink = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #253745;
  text-decoration: none;

  &.active {
    color: #247c66;
  }
`;

const GITHUB_CLIENT_IDS = {
  "http://localhost": "39f3aeaed6b9a3bd65e3",
  "https://staging.ikinciel.dev": "157092e5ea40aa51b210",
};

const clientId = () => {
  const { origin } = window.location;
  let id = "9962fc2dac9107898781";

  Object.keys(GITHUB_CLIENT_IDS).forEach((key) => {
    if (origin.includes(key)) {
      id = GITHUB_CLIENT_IDS[key];
    }
  });

  return id;
};

const GITHUB_LOGIN_LINK = `https://github.com/login/oauth/authorize?client_id=${clientId()}&redirect_uri=${
  window.location.origin
}/github-callback&scope=user:email`;

const MobileMenuContainer = styled.div`
  background: white;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 50px;
  border: 1px solid #e1e1e1;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;

  & a {
    display: block;
  }

  & a.github-button {
    display: flex;
  }
`;

export default function Navbar() {
  const mobileMenuRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { pathname } = location;

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  const handleLogout = () => {
    dispatch(clear());
    navigate("/");
  };

  const handleTitleClick = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box>
      <Container>
        <AppLink to="/" onClick={handleTitleClick}>
          <GreenIcon />
          <SiteTitle>
            ikinciel<Domain>.dev</Domain>
          </SiteTitle>
        </AppLink>
        <Navigations>
          <PageLink
            to="/about"
            className={pathname === "/about" ? "active" : ""}
          >
            hakkında
          </PageLink>
          <PageLink to="/" className={pathname === "/" ? "active" : ""}>
            ilanlar
          </PageLink>
          {auth.isLogged && (
            <>
              <NavbarLink
                to="/my-posts"
                className={pathname === "/my-posts" ? "active" : ""}
              >
                @{auth?.user?.login}
              </NavbarLink>
              <StyledLink onClick={handleLogout}>çıkış</StyledLink>
              <Button onClick={() => navigate("/new-post")}>ilan ekle</Button>
            </>
          )}
          {!auth.isLogged && location.pathname !== "/github-callback" && (
            <GitHubButton href={GITHUB_LOGIN_LINK}>
              <img src={GitHubLogo} alt="GitHub Logo" />
              GitHub ile Oturum Aç
            </GitHubButton>
          )}
        </Navigations>
        <MobileMenus
          isOpen={isOpen}
          setOpen={setOpen}
          mobileMenuRef={mobileMenuRef}
        />
        {isOpen && (
          <MobileMenuContainer ref={mobileMenuRef}>
            <PageLink
              to="/about"
              className={pathname === "/about" ? "active" : ""}
            >
              hakkında
            </PageLink>
            <PageLink to="/" className={pathname === "/" ? "active" : ""}>
              ilanlar
            </PageLink>
            {auth.isLogged && (
              <>
                <ALink
                  onClick={() => navigate("/new-post")}
                  className={pathname === "/new-post" ? "active" : ""}
                >
                  gönder
                </ALink>
                <ALink
                  onClick={() => navigate("/my-posts")}
                  className={pathname === "/my-posts" ? "active" : ""}
                >
                  @{auth?.user?.login}
                </ALink>
                <ALink onClick={handleLogout}>çıkış</ALink>
              </>
            )}
            {!auth.isLogged && location.pathname !== "/github-callback" && (
              <GitHubButton href={GITHUB_LOGIN_LINK} className="github-button">
                <img src={GitHubLogo} alt="GitHub Logo" />
                GitHub ile Oturum Aç
              </GitHubButton>
            )}
          </MobileMenuContainer>
        )}
      </Container>
    </Box>
  );
}
