import { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledBox = styled.input`
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-top: 2px;
`;

const StyledLabel = styled.label`
  color: ${(props) => (props.disabled ? "#888" : "rgb(24, 24, 27)")};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export default function Textarea({
  label,
  disabled = false,
  containerStyles = {},
  ...props
}) {
  const [uuid] = useState(`checkbox-${uuidv4()}`);

  return (
    <Container style={containerStyles}>
      <StyledBox
        type="checkbox"
        id={uuid}
        name={uuid}
        disabled={disabled}
        {...props}
      />
      <StyledLabel htmlFor={uuid} disabled={disabled}>
        {label}
      </StyledLabel>
    </Container>
  );
}
