import { useEffect, useState } from "react";
import SelectBox from "../SelectBox";
import axe from "../../../helpers/axe";

export default function CategorySelect({
  width,
  defaultValue,
  value,
  handleChange,
  ...props
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const items = await axe("categories/all").sort("name").fetch();
      setOptions(items);
    };

    fetchOptions();
  }, []);

  return (
    <SelectBox
      value={value ? options.find((i) => i.id === value) : null}
      defaultValue={options.find((i) => i.id === defaultValue)}
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => option.tr_name}
      getOptionValue={(option) => option.id}
      isSearchable={true}
      isClearable={true}
      width={width}
      {...props}
    />
  );
}
