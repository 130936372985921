import AsyncSelectBox from "../AsyncSelectBox";
import axe from "../../../helpers/axe";
import { debounce } from "lodash";
import { useEffect, useState, useCallback } from "react";

export default function PlaceSelect({
  defaultValue,
  value = undefined,
  handleChange,
  ...props
}) {
  const [option, setOption] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const loadOptions = debounce(async (inputValue, callback) => {
    setLoading(true);
    const { data } = await axe("places")
      .whereLike("title", `*${inputValue}*`)
      .paginate(1, 20);
    setLoading(false);
    callback(data);
  }, 300);

  const getOption = useCallback(async () => {
    const item = await axe(`places/${value}`).fetch();
    setOption(item);
  }, [value]);

  useEffect(() => {
    if (value) {
      getOption();
    } else {
      setOption(null);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleLocalChange = (value) => {
    setOption(value);
    handleChange(value);
  };

  return (
    <AsyncSelectBox
      value={option}
      onChange={handleLocalChange}
      loadOptions={loadOptions}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      isLoading={isLoading}
      placeholder="Search"
      {...props}
    />
  );
}
