import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Posts from "../PostListPage/Posts";
import axe from "../../../helpers/axe";
import Pagination from "../PostListPage/Pagination";
import styled from "styled-components";
import LinkButton from "../../../components/Button";

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

export default function MyPostsPage() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(useLocation().search);
  const [pagination, setPagination] = useState(null);
  const [filters, setFilters] = useState({
    page: parseInt(query.get("page"), 10) || 1,
    per_page: parseInt(query.get("per_page"), 10) || 20,
  });

  useEffect(() => {
    if (!auth.isLogged) {
      return navigate("/");
    }

    const getPosts = async () => {
      const response = await axe("posts")
        .where("user_id", auth?.user?.id)
        .with(
          "place{title},user{github_name|github_username|github_avatar_url},images"
        )
        .sort("-created_at")
        .paginate(filters.page, filters.per_page);
      setPagination(response);
    };

    getPosts();
    // eslint-disable-next-line
  }, [filters, auth]);

  if (!pagination) return "yükleniyor...";

  return (
    <>
      <Title>
        <div>@{auth?.user?.login}</div>
        <LinkButton onClick={() => navigate("/profile-options")}>
          Ayarlar
        </LinkButton>
      </Title>
      <Posts items={pagination.data} />
      <Pagination
        data={pagination.pagination}
        onChange={(value) => setFilters({ ...filters, page: value })}
      />
    </>
  );
}
