import { useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { UploadIcon } from "../Icons";
import Loading from "../Loading";

const Container = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const Box = styled.div`
  background: #fff;
  border: 1px dashed #ccc;
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
  border-radius: 3px;
  overflow: hidden;
`;

const SelectBox = styled(Box)``;

const SelectButton = styled.button`
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: white;
  color: #333;
  transition: background-color 100ms linear;
  position: relative;

  &:hover {
    background: #f1f1f1;
  }

  &:disabled {
    background: #f1f1f1;
  }
`;

const StyledImage = styled.img`
  height: 100%;
  transition: opacity 100ms linear;

  &:hover {
    opacity: 0.5;
  }
`;

const RemoveButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  color: black;
  text-align: center;
  border: none;
  background: transparent;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const uploadFiles = async (files) => {
  const formData = new FormData();
  for (const image of files) {
    formData.append("images", image);
  }

  return await axios({
    method: "post",
    url: "upload",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export default function ImageUploader({
  // onChange,
  // defaultValue = [],
  max = null,
  isUploading,
  setIsUploading,
  images,
  setImages,
  ...props
}) {
  const fileInputRef = useRef(null);

  const handleFileSelection = async (event) => {
    if (max !== null && event.target.files.length > max - images.length) {
      return toast.error(`You cannot select more the ${max} files`);
    }

    try {
      setIsUploading(true);
      const { data } = await uploadFiles(event.target.files);
      setImages([...images, ...data]);
      setIsUploading(false);
    } catch (error) {
      toast.error("Files couldn't be uploaded.");
      setIsUploading(false);
    }
  };

  const handleRemoveImage = (selectedIndex) => {
    const newImages = images.filter((image, index) => index !== selectedIndex);
    setImages(newImages);
  };

  return (
    <Container>
      <HiddenFileInput
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelection}
        {...props}
      />
      {images.map((image, index) => (
        <div key={index}>
          <SelectBox>
            <StyledImage src={image} />
          </SelectBox>
          <RemoveButton type="button" onClick={() => handleRemoveImage(index)}>
            Sil
          </RemoveButton>
        </div>
      ))}
      {(images || []).length < 5 && (
        <SelectBox>
          <SelectButton
            type="button"
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
          >
            {!isUploading && <UploadIcon />}
            {isUploading && <Loading />}
          </SelectButton>
        </SelectBox>
      )}
    </Container>
  );
}
