import styled from "styled-components";

const StyledInput = styled.input`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border: 1px solid rgb(228, 228, 231);
  border-radius: 4px;
  color: rgb(0, 0, 0);
  background: rgb(250, 250, 250);
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #ddd;
    color: rgb(153, 153, 153);
  }
`;

export default function TextInput({ type = "text", ...props }) {
  return <StyledInput type={type} {...props} />;
}
