import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  padding: 10px;
  flex-wrap: wrap;
`;

const Page = styled.button`
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 6px 12px;
  display: inline;
  font-size: 16px;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "600" : "500")};
  background: ${(props) => (props.active ? "black" : "#ddd")};
  color: ${(props) => (props.active ? "#fff" : "#444")};

  &:hover {
    background: ${(props) => (props.active ? "#333" : "#f4f4f4")};
    border: 1px solid #e6e6e6;
  }

  &:focus {
    outline: none;
  }
`;

export default function Pagination({ onChange, data }) {
  if (data.lastPage <= 1) {
    return "";
  }

  return (
    <Container>
      {Array.from(Array(data.lastPage).keys()).map((index) => (
        <Page
          key={index}
          type="button"
          active={index + 1 === data.currentPage}
          onClick={() => onChange(index + 1)}
        >
          {index + 1}
        </Page>
      ))}
    </Container>
  );
}
