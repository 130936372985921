import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "../../components/Product";
import axe from "../../helpers/axe";

const Container = styled.div`
  padding: 70px 0px 150px 0px;
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #247c66;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin: 10px 0px 50px 0px;
`;

const Columns = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export default function EditorChoiceSection() {
  const [items, setItems] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      const query = axe("posts");

      const { data } = await query
        .with(
          "place{title},user{github_name|github_username|github_avatar_url},images"
        )
        .sort("-created_at")
        .paginate(1, 3);
      setItems(data);
    };

    getPosts();
  }, []);

  return (
    <Container>
      <H3>Son İlanlar</H3>
      <Columns>
        {items && items.map((item, index) => <Product key={index} {...item} />)}
      </Columns>
    </Container>
  );
}
