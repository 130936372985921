import { useEffect, useState, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import {
  PostListPage,
  GitHubLoginPage,
  NewPostPage,
  ProfileOptions,
  PostPage,
  MyPostsPage,
  PrivacyPage,
  TermsPage,
  CookiePage,
  HomePage,
} from "./pages";
import { STORAGE } from "./constants";
import { set } from "./store/slices/auth";
import { setFilters } from "./store/slices/filters";
import Navbar from "./components/Navbar";
import Button from "./components/Button";
import Footer from "./components/Footer";
import ToTheTop from "./components/ToTheTop";

const PageBox = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
`;

const Container = styled.div`
  width: 1200px;
  padding: 20px 0px;
  min-height: 800px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    padding: 20px;
  }
`;

const CookieConsentContainer = styled.div`
  position: fixed;
  bottom: 0px;
  background: rgb(244, 244, 245);
  border-top: 1px solid #ddd;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const CenteredText = styled.div`
  max-width: 1200px;
  left: 0px;
  right: 0px;
  margin: auto;
  font-size: 14px;
  line-height: 24px;
  display: flex;
`;

const OkButton = styled(Button)`
  height: 24px;
`;

const CookieConsent = () => {
  const [isOk, setOk] = useState();

  useEffect(() => {
    const isOk = localStorage.getItem("cookie-consent-ok");
    setOk(isOk);
  }, []);

  if (isOk || isOk === undefined) {
    return "";
  }

  const handleClick = () => {
    localStorage.setItem("cookie-consent-ok", true);
    setOk(true);
  };

  return (
    <CookieConsentContainer>
      <CenteredText>
        <div>
          Notice. This site uses cookies to provide necessary website
          functionality, improve your experience and analyze our traffic. By
          using our website, you agree to our Privacy Policy and our Cookie
          Policy.
        </div>
        <OkButton type="button" onClick={handleClick}>
          OK
        </OkButton>
      </CenteredText>
    </CookieConsentContainer>
  );
};

const ScrollController = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return <>{children}</>;
};

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      const token = localStorage.getItem(STORAGE.TK);
      if (token) {
        try {
          const { data: user } = await axios.get("me");
          if (user.default_country_id) {
            dispatch(
              setFilters({
                place_id: user.default_country_id,
              })
            );
          }
          dispatch(
            set({
              token,
              user: user,
            })
          );
          setLoading(false);
        } catch (error) {
          localStorage.removeItem(STORAGE.TK);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    getUser();
    // eslint-disable-next-line
  }, []);

  if (loading) return "";

  return (
    <>
      <Navbar />
      <PageBox>
        <Container>
          <ScrollController>
            <Routes>
              <Route path="/" element={<PostListPage />} replace />
              <Route path="/about" element={<HomePage />} replace />
              <Route path="/my-posts" element={<MyPostsPage />} replace />
              <Route
                path="/profile-options"
                element={<ProfileOptions />}
                replace
              />
              <Route path="/post/:slug/:id" element={<PostPage />} replace />
              <Route path="/ilan/:slug/:id" element={<PostPage />} replace />
              <Route path="/new-post/:id" element={<NewPostPage />} />
              <Route path="/new-post" element={<NewPostPage />} />
              <Route path="/github-callback" element={<GitHubLoginPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/cookie-policy" element={<CookiePage />} />
            </Routes>
          </ScrollController>
          <ReactTooltip />
        </Container>
      </PageBox>
      <ToTheTop />
      <Footer />
      <CookieConsent />
    </>
  );
}

export default App;
