import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #253745;
  text-decoration: none;

  &.active {
    color: #247c66;
  }
`;

export default function NavbarLink({ children, ...props }) {
  return <StyledLink {...props}>{children}</StyledLink>;
}
