import React, { useEffect } from "react";
import styled from "styled-components";
import { HamburgerIcon } from "../Icons";

const Container = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    position: relative;
  }
`;

const Trigger = styled.button`
  background: white;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default function MobileMenus({ isOpen, setOpen, mobileMenuRef }) {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(mobileMenuRef);

  const handleTriggerClick = (event) => {
    setOpen(!isOpen);
    event.stopPropagation();
  };

  return (
    <Container>
      <Trigger onMouseDown={handleTriggerClick}>
        <HamburgerIcon />
      </Trigger>
    </Container>
  );
}
