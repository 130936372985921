import styled from "styled-components";

const StyledA = styled.a`
  background: #247c66;
  color: rgb(244, 244, 245);
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;

  &.block {
    display: block;
    box-sizing: border-box;
    padding: 14px 20px;
    width: 100% !important;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &:disabled {
    background: #d9d9d9;
    color: #888;
  }

  @media only screen and (max-width: 768px) {
    padding: 4px 8px;
  }
`;

export default function LinkButton({ block = false, children, ...props }) {
  return (
    <StyledA className={block ? "block" : ""} {...props}>
      {children}
    </StyledA>
  );
}
