import React, { useState } from "react";
import styled from "styled-components";
import { ArrowDownIcon, ArrowUpIcon } from "../../components/Icons";

const Container = styled.div`
  padding: 50px 0px;
`;

const H4 = styled.h4`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #253745;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0px;
`;

const Description = styled.p`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #253745;
`;

const Box = styled.div`
  background: #f9fbfd;
  border: 1px solid #e5e9ed;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
`;

const Title = styled.div`
  background: #253745;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 20px 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #253745;
`;

const ToggleButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
`;

const Question = ({ defaultOpen = false, title, children }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  return (
    <Box>
      <Title onClick={() => setOpen(!isOpen)}>
        {title}
        <ToggleButton type="button" onClick={() => setOpen(!isOpen)}>
          {isOpen && <ArrowUpIcon />}
          {!isOpen && <ArrowDownIcon />}
        </ToggleButton>
      </Title>
      {isOpen && <Content>{children}</Content>}
    </Box>
  );
};

const MaxContent = styled.div`
  width: 700px;
  left: 0px;
  right: 0px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export default function FAQSection() {
  return (
    <Container>
      <H4>Sıkça Sorulan Sorular</H4>
      <Description>Burada aradığınız tüm cevapları bulabilirsiniz.</Description>

      <MaxContent>
        <Question
          title="Bu hizmetten yararlanmanın fiyatı nedir?"
          defaultOpen={true}
        >
          Bu hizmetin bedeli yoktur. Yeni reklamlar oluşturmak, alıcılar ve
          satıcılar için tamamen ücretsizdir. Bu hizmeti kullanmak için sizden
          ücret alınmaz.
        </Question>
        <Question title="GitHub hesabı nedir?">
          Github, geliştiricilerin ve programcıların kod üzerinde işbirliği
          içinde çalışmalarını sağlayan bir web platformudur.
        </Question>
        <Question title="Neden bir GitHub hesabına ihtiyacım var?">
          Kullanıcıların gerçek yazılım geliştiricileri olup olmadıklarını,
          halka açık GitHub hesaplarının etkinliklerini kontrol ederek kolayca
          doğrulayabiliyoruz. Bu yüzden gerçek bir geliştirici olduğunuzu
          kanıtlamak için aktif bir GitHub hesabına ihtiyacınız var.
        </Question>
        <Question title="GitLab, BitBucket veya diğer hizmet hesaplarımı kullanabilir miyim?">
          Henüz değil, ancak gelecekte bu hizmetleri de eklemeyi planlıyoruz.
        </Question>
        <Question title="İlanların ikinciel.dev tarafından mı satılıyor?">
          Hayır. ikinciel.dev olarak burada bir şey satmıyoruz. Biz sadece
          satıcılar ve alıcılar için bir platform sağlıyoruz. Burada listelenen
          tüm ürünler geliştiriciler tarafından yayınlanmıştır.
        </Question>
        <Question title="Satıcının iletişim bilgilerini neden göremiyorum?">
          Geliştiriciler için güvenli bir satış platformu sağlamayı
          hedefliyoruz. Bu nedenle bir gönderinin iletişim bilgilerini yalnızca
          gerçek geliştiriciler görebilir. Etkin bir GitHub hesabı, alıcının
          gerçek bir geliştirici olduğunu kanıtlar.
        </Question>
        <Question title="Satıcı ile nasıl iletişim kurabilirim?">
          Doğrulanmış bir GitHub hesabınız varsa, giriş yaptıktan sonra
          alıcıların iletişim bilgilerini görebilirsiniz. Alıcılar, e-posta,
          telefon veya sosyal medya hesap adresi gibi herhangi bir iletişim
          türünü seçebilir.
        </Question>
      </MaxContent>
    </Container>
  );
}
