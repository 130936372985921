import React from "react";
import styled from "styled-components";
import team from "./team.svg";

const Green = styled.span`
  color: #247c66;
  font-weight: bold;
`;

const Container = styled.div`
  padding: 100px 0px;
  margin-bottom: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 50px;
  }
`;

const H4 = styled.h4`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #253745;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

const ImageContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Texts = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: rgba(37, 55, 69, 0.95);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

const Description = styled.p`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: rgba(37, 55, 69, 0.7);
  padding-right: 50px;
  margin-bottom: 50px;
`;

const Link = styled.a`
  background: #253745;
  padding: 12px 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #f9fbfd;
  text-decoration: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
`;

const TextLink = styled.a`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: rgba(37, 55, 69, 0.7);
  color: #247c66;
  text-decoration: none;
  font-weight: bold;
`;

const NewLine = styled.br`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default function AboutUsSection() {
  return (
    <Container>
      <H4>Hakkımızda</H4>
      <Content>
        <ImageContainer>
          <img src={team} alt="ikinciel.dev ekip" />
        </ImageContainer>
        <Texts>
          <Title>
            Biz yüksek <Green>enerji</Green> ve <Green>motivasyona</Green> sahip
            bir <NewLine />
            <Green>start-up</Green> ekibiyiz.
          </Title>
          <Description>
            Kullanımı <Green>kolay</Green> bir ürün yaratmak için elimizden
            gelenin en iyisini yapıyoruz. <br />
            <br />
            İkinci el ürün kullanımına katkıda bulunarak <Green>
              doğaya
            </Green>{" "}
            yardımcı olmaya çalışıyoruz. Bir dizüstü bilgisayarın{" "}
            <Green>karbon ayak izinin</Green> ne olduğunu biliyor musunuz?
            <br />
            <br />
            Bu konuda bir makale okumak için{" "}
            <TextLink
              href="https://circularcomputing.com/news/carbon-footprint-laptop"
              target="_blank"
              rel="noreferrer noopenner"
            >
              buraya tıklayın.
            </TextLink>
          </Description>
          <Link
            href="https://www.carbonfootprint.com/"
            target="_blank"
            rel="noreferrer noopenner"
          >
            Karbon ayak izi nedir?
          </Link>
        </Texts>
      </Content>
    </Container>
  );
}
