import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import PostFilter from "./PostFilter";
import Posts from "./Posts";
import axe from "../../../helpers/axe";
import styled from "styled-components";
import { setFilters } from "../../../store/slices/filters";
import { pushItems, clearItems } from "../../../store/slices/list";

const Green = styled.span`
  color: #247c66;
`;

const Container = styled.div`
  transition: 0.3s;

  @media only screen and (max-width: 768px) {
    margin-top: ${(props) => (props.filterStatus ? "270px" : "46px")};
  }
`;

const PHContainer = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;

  @media only screen and (max-width: 768px) {
    left: 0px;
    right: 0px;
    bottom: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #253745;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export default function PostListPage() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const filters = useSelector((state) => state.filters);
  const list = useSelector((state) => state.list);
  const [pagination, setPagination] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState(false);

  const handleChange = (change) => {
    Object.keys(change).forEach((key) => {
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: "filter_updated",
      //     count: 1,
      //     segmentation: {
      //       filter: key,
      //       value: change[key],
      //     },
      //   },
      // ]);
    });

    dispatch(setFilters(change));
  };

  useEffect(() => {
    const allowedKeys = ["category_id", "place_id", "search_text"];
    Object.keys(filters).forEach((key) => {
      if (allowedKeys.includes(key)) {
        query.set(key, filters[key]);
      }
    });

    navigate({ search: query.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getPosts = async (page) => {
    const query = axe("posts")
      .where("archived_at", null)
      .where("is_sold", false);

    if (filters.category_id) {
      query.where("category_id", filters.category_id);
    }

    if (filters.place_id) {
      query.where("place_id", filters.place_id);
    }

    if (filters.search_text && filters.search_text?.length > 0) {
      query.whereLike("title", `*${filters.search_text}*`);
    }

    const response = await query
      .with(
        "place{title},user{github_name|github_username|github_avatar_url},images"
      )
      .sort("-created_at")
      .paginate(page, filters.per_page);
    dispatch(pushItems({ items: response.data }));
    setPagination(response.pagination);
  };

  useEffect(() => {
    dispatch(clearItems());
    getPosts(1);
    // eslint-disable-next-line
  }, [filters.category_id, filters.place_id, filters.search_text, auth]);

  const loadMore = () => {
    dispatch(setFilters({ ...filters, page: filters.page + 1 }));
    getPosts(filters.page + 1);
  };

  if (!pagination) return "";

  return (
    <>
      <Title>
        Filtrelerinize göre <Green>{pagination.total} ilan</Green> var...
      </Title>
      <PostFilter
        filters={filters}
        handleChange={handleChange}
        filterStatus={filterStatus}
        handleFilterStatus={() => setFilterStatus(!filterStatus)}
      />
      <Container filterStatus={filterStatus}>
        <InfiniteScroll
          dataLength={list.items?.length}
          next={loadMore}
          hasMore={list.items?.length < pagination.total}
          loader={<h4>Yükleniyor...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Tüm ilanlari görüntülediniz.</b>
            </p>
          }
        >
          <Posts items={list.items} />
        </InfiniteScroll>
      </Container>
      <PHContainer>
        <a
          href="https://www.producthunt.com/posts/secondhand-dev?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-secondhand&#0045;dev"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=339019&theme=light&period=daily"
            alt="secondhand&#0046;dev - A&#0032;platform&#0032;to&#0032;buy&#0032;and&#0032;sell&#0032;used&#0032;electronics&#0032;for&#0032;developers&#0046; | Product Hunt"
            style={{ width: "250px", height: "54px" }}
          />
        </a>
      </PHContainer>
    </>
  );
}
