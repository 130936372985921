import styled from "styled-components";

export const StyledH1 = styled.h1`
  color: #444;
`;
export const StyledH2 = styled.h2`
  color: #444;
`;
export const StyledH3 = styled.h3`
  color: #444;
`;
export const StyledP = styled.p`
  line-height: 30px;
  color: #333;
`;
export const StyledUl = styled.ul``;
export const StyledLi = styled.li`
  margin-bottom: 10px;
  color: #333;
`;

export const NAME = "ikinciel.dev";
