import React from "react";
import styled from "styled-components";
import work1 from "./work-1.svg";
import work2 from "./work-2.svg";
import work3 from "./work-3.svg";
import work4 from "./work-4.svg";

const Container = styled.div`
  background: #1e425e;
  margin-left: -100%;
  margin-right: -100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: unset;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    padding: 0px 20px;
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 70px 0px 90px 0px;
`;

const H4 = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  color: #f9fbfd;
  margin: 0px;
`;

const DescriptionContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0px;
`;

const Description = styled.p`
  text-align: center;
  font-family: "Nunito";
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #f9fbfd;
  max-width: 600px;
  margin: 0px 0px 30px 0px;
`;

const Boxes = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Box = styled.div`
  background: #f9fbfd;
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  height: 260px;
  width: 260px;
  position: relative;

  @media only screen and (max-width: 768px) {
  }
`;

const Number = styled.div`
  position: absolute;
  font-weight: 700;
  font-size: 60px;
  color: rgba(37, 55, 69, 0.1);
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

const Number1 = styled(Number)`
  left: 10px;
  bottom: 0px;
`;

const Number2 = styled(Number)`
  top: 10px;
  right: 10px;
`;

const Number3 = styled(Number)`
  bottom: 40px;
  right: 10px;
`;

const Number4 = styled(Number)`
  left: 10px;
  top: 10px;
`;

const Image = styled.img`
  position: absolute;
  top: 30px;
  left: 0px;
  right: 0px;
  margin: auto;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #253745;
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
  width: 100%;
`;

export default function WorkingProcessSection() {
  return (
    <Container>
      <Content>
        <H4>Nasıl Çalışır?</H4>
        <DescriptionContent>
          <Description>
            Aktif bir GitHub hesabı developer doğrulaması için kullandığımız
            önemli bir kriterdir.
          </Description>
        </DescriptionContent>
        <Boxes>
          <Box>
            <Number1>1</Number1>
            <Image src={work1} />
            <Text>
              GitHub hesabı <br /> edin
            </Text>
          </Box>
          <Box>
            <Number2>2</Number2>
            <Image src={work2} />
            <Text>
              Aktiviteni <br /> arttır
            </Text>
          </Box>
          <Box>
            <Number3>3</Number3>
            <Image src={work3} />
            <Text>Oturum aç</Text>
          </Box>
          <Box>
            <Number4>4</Number4>
            <Image src={work4} />
            <Text style={{ bottom: "40px" }}>
              Satın al <br /> ya da sat
            </Text>
          </Box>
        </Boxes>
      </Content>
    </Container>
  );
}
