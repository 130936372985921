import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: null,
  items: [],
};

export const listSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearItems: (state) => {
      state.items = [];
    },
    pushItems: (state, { payload }) => {
      state.items.push(...payload.items);
    },
    setData: (state, { payload }) => {
      state.response = payload.response;
    },
  },
});

export const { setData, clearItems, pushItems } = listSlice.actions;

export default listSlice.reducer;
