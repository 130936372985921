import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { UpIcon } from "../Icons";

const firstSight = keyframes`
  0% {
     opacity:0;
  }

  100% {
    opacity:1;
  }
`;

const Container = styled.a`
  animation: 500ms ease-out 0s 1 ${firstSight};
  background-color: white;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99999;
  color: #247c66;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & svg {
    margin: -10px;
  }

  @media only screen and (max-width: 768px) {
    right: 20px;
    bottom: 20px;
  }
`;

export default function ToTheTop() {
  const [isVisible, setVisible] = useState(false);

  const handleClick = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    setVisible(window.pageYOffset > 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!isVisible) {
    return "";
  }

  return (
    <Container onClick={handleClick}>
      <UpIcon />
    </Container>
  );
}
