import React from "react";
import styled from "styled-components";

const Green = styled.span`
  color: #247c66;
`;

const Container = styled.div`
  background: #e5e9ed;
  margin-left: -100%;
  margin-right: -100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: unset;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    padding: 0px 20px;
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 50px 0px 90px 0px;
`;

const H4 = styled.h4`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #253745;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0px;
`;

const Description = styled.p`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #253745;
  margin-bottom: 60px;
`;

const StyledBox = styled.div`
  background: #f9fbfd;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  min-height: 120px;
`;

const BoxTitle = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #247c66;
  margin: 0px 0px 10px 0px;
`;

const BoxDescription = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(37, 55, 69, 0.6);
`;

const Box = ({ title, children }) => {
  return (
    <StyledBox>
      <BoxTitle>{title}</BoxTitle>
      <BoxDescription>{children}</BoxDescription>
    </StyledBox>
  );
};

const Boxes = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const NewLine = styled.br`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default function BenefitsSections() {
  return (
    <Container>
      <Content>
        <H4>Avantajlar</H4>
        <Description>
          <Green>ikinciel</Green>.dev hem geliştiriciler hem de çevre{" "}
          <NewLine /> için oldukça yararlıdır.
        </Description>

        <Boxes>
          <Box title="Güvenilirlik">
            Kayıt oluşturabilen her geliştirici gerçekten kiminle alışveriş
            yaptığınızın doğrulamasıdır.
          </Box>
          <Box title="Network">
            Alışveriş yaparak iş ağınızı genişletebilirsiniz. Bu yeni insanlarla
            tanışmak için harika bir yoldur.
          </Box>
        </Boxes>
        <Boxes>
          <Box title="Düşük maliyet">
            Tüm ihtiyaçlarınızı daha düşük maliyetlerle karşılayabilirsiniz.
          </Box>
          <Box title="Çevre">
            Satın aldığınız ürünlerin daha uzun yıllar boyunca kullanılmasını
            sağlayarak doğaya pozitif bir katkıda bulunabilirsiniz.
          </Box>
        </Boxes>
      </Content>
    </Container>
  );
}
