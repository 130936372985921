import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "normalize.css";
import "./pages/styles.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import { STORAGE } from "./constants";
import "react-toastify/dist/ReactToastify.css";
import "react-image-lightbox/style.css";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/tr";
import api from "axe-api-client";

Sentry.init({
  dsn: "https://e1eba7521d8849ca93425a19300b3d8e@o1079064.ingest.sentry.io/6083641",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const API_HOSTS = {
  "http://localhost": "http://localhost:4000/api/v1",
  "https://staging.ikinciel.dev": "https://api-staging.ikinciel.dev/api/v1",
};

const getAPIUrl = () => {
  const { origin } = window.location;
  let api = "https://api.ikinciel.dev/api/v1";

  Object.keys(API_HOSTS).forEach((key) => {
    if (origin.includes(key)) {
      api = API_HOSTS[key];
    }
  });

  return api;
};

window.isTurkishHost = window?.location?.hostname?.includes("ikinciel.dev");

const CURRENT_LOCALE = window.isTurkishHost ? "tr" : "en";

dayjs.locale(CURRENT_LOCALE);

axios.interceptors.request.use(
  function (config) {
    config.url = `${getAPIUrl()}/${config.url}`;
    const token = localStorage.getItem(STORAGE.TK);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.setConfig({
  baseURL: getAPIUrl(),
});

api.interceptors.addRequest((request) => {
  const token = localStorage.getItem(STORAGE.TK);
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }

  return request;
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
