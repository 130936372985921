import styled from "styled-components";
import { GitHubIcon } from "../../../components/Icons";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 96px;
  width: 96px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
`;

const Data = styled.div`
  width: 100%;
`;

const Username = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-bottom: 5px;
  color: #253745;
`;

const GitHubContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const IconContainer = styled.div`
  margin-top: 5px;
  margin-left: -10px;
  margin-right: -5px;
  & svg {
    height: 24px;
  }
`;

const GitHubUsername = styled.a`
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  color: #253745;
`;

const ContactInfo = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #253745;
`;

const DEFAULT_USER = {
  github_avatar_url: "/octocat.jpg",
  github_name: "Unknown User",
  contact_info: "You must be logged in to see contact data.",
};

export default function Contact({ post }) {
  const { user } = post;
  const currentUser = user?.github_avatar_url ? user : DEFAULT_USER;

  return (
    <Container>
      <Avatar src={currentUser.github_avatar_url} />
      <Data>
        <Username>
          {currentUser?.github_name || currentUser?.github_username}
        </Username>
        {currentUser?.github_username && (
          <GitHubContainer>
            <IconContainer>
              <GitHubIcon />
            </IconContainer>
            <GitHubUsername
              href={`https://github.com/${currentUser?.github_username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              @{currentUser?.github_username}
            </GitHubUsername>
          </GitHubContainer>
        )}
        <ContactInfo>
          {post.contact_info || currentUser.contact_info}
        </ContactInfo>
      </Data>
    </Container>
  );
}
