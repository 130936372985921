import axios from "axios";

class Query {
  constructor(resource) {
    this.resource = resource;
    this.fields = [];
    this.sorts = [];
    this.wheres = [];
    this.withQuery = null;
  }

  select(value) {
    if (Array.isArray) {
      this.fields.push(...value);
    } else {
      this.fields.push(value);
    }
    return this;
  }

  sort(value) {
    if (typeof value === "string") {
      this.sorts.push(value);
    } else if (Array.isArray(value)) {
      this.sorts.push(...value);
    }
    return this;
  }

  with(value) {
    this.withQuery = value;
    return this;
  }

  where(column, operator, value, prefix = "") {
    if (value === undefined || value === null) {
      value = operator;
      operator = "";
    }

    if (operator === "=") {
      operator = "";
    }

    this.wheres.push({
      [`${prefix}${column}${operator}`]: value,
    });

    return this;
  }

  whereNot(column, value) {
    return this.where(column, ".$not", value);
  }

  whereLike(column, value) {
    return this.where(column, ".$like", value);
  }

  orWhereLike(column, value) {
    return this.where(column, ".$like", value, "$or.");
  }

  orWhere(column, operator, value) {
    return this.where(column, operator, value, "$or.");
  }

  async fetch(rest = {}) {
    const { status, data } = await axios({
      method: "get",
      url: this.resource,
      params: {
        q:
          this.wheres.length > 0
            ? JSON.stringify(this.wheres).replace(/\s/g, "")
            : undefined,
        sort: this.sorts.length > 0 ? this.sorts.join(",") : undefined,
        with: this.withQuery ? this.withQuery : undefined,
        fields: this.fields ? this.fields.join(",") : undefined,
        ...rest,
      },
    });

    if (status === 200) {
      return data;
    }

    return [];
  }

  async first() {
    return await this.fetch();
  }

  async paginate(page = 1, perPage = 10) {
    return await this.fetch({
      page: parseInt(page, 10),
      per_page: parseInt(perPage, 10),
    });
  }
}

const builder = (resource) => {
  return new Query(resource);
};

export default builder;
