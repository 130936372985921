import { createSlice } from "@reduxjs/toolkit";
import { STORAGE } from "../../constants";

const initialState = {
  isLogged: false,
  token: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.user;
      state.isLogged = true;
      localStorage.setItem(STORAGE.TK, payload.token);
      // send user details to countly
      // Countly.q.push([
      //   "user_details",
      //   {
      //     name: state.user.name,
      //     username: state.user.login,
      //     picture: state.user.avatar_url,
      //   },
      // ]);
    },
    clear: (state) => {
      localStorage.removeItem(STORAGE.TK);
      state.isLogged = false;
      state.token = null;
      state.user = null;
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: "logout",
      //     count: 1,
      //   },
      // ]);
    },
  },
});

export const { set, clear } = authSlice.actions;

export default authSlice.reducer;
