import styled from "styled-components";

const StyledButton = styled.button`
  background: rgb(24, 24, 27);
  color: rgb(244, 244, 245);
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  padding: 5px 8px;
  cursor: pointer;
  text-decoration: none;

  &.block {
    display: block;
    box-sizing: border-box;
    padding: 14px 20px;
    width: 100% !important;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &:disabled {
    background: #d9d9d9;
    color: #888;
  }
`;

export default function Button({ children, block = false, ...props }) {
  return (
    <StyledButton type="button" className={block ? "block" : ""} {...props}>
      {children}
    </StyledButton>
  );
}
