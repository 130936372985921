export const SELECT_STYLES = {
  container: (base, state) => ({
    ...base,
    width: "100%",
  }),
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: 400,
      padding: "0px",
      background: state.isDisabled ? "#ddd" : "rgb(250, 250, 250)",
      // width: "180px",
      boxShadow: state.isFocused ? 0 : 0,
      border: "1px solid rgb(228, 228, 231)",
      "&:hover": {
        border: "1px solid rgb(228, 228, 231)",
      },
    };
  },
  input: (provided, state) => {
    return {
      ...provided,
      fontSize: "12px",
      fontWeight: 400,
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: "14px",
      fontWeight: 400,
      cursor: "pointer",
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      marginTop: 0,
    };
  },
};
