import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Price from "../Price";
import { SparkleIcon } from "../Icons";

const Column = styled(Link)`
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(37, 55, 69, 0.15);
  border-radius: 10px;
  width: 386px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-decoration: none;
  border: 1px solid #f1f1f1;
  position: relative;

  &:hover {
    transform: scale(1.02);

    div.label {
      opacity: 1;
      color: white;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    border: 1px solid #e1e1e1;
  }

  &.is-pale {
    h4,
    p,
    div {
      color: #ccc;
    }
  }
`;

const ImageContainer = styled.div`
  margin-top: 10px;
  width: 388px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  & img {
    width: calc(100% - 30px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 20px 15px;
  height: 146px;
  overflow: hidden;
  margin-bottom: 40px;
`;

const ProductTitle = styled.h4`
  margin: 0px 0px 10px 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #253745;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
`;

const ProductContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: rgba(37, 55, 69, 0.7);
`;

const PriceRow = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 15px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: right;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #253745;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const getDefaultCurrentImage = (image, images) => {
  if (images && images.length > 0) {
    image = images[0]?.url;
  }

  if (!image) {
    image = "/no-img2.png";
  }

  return image;
};

const MainImage = styled(LazyLoadImage)`
  width: 388px;
  height: 200px;
  object-fit: cover;
`;

const Place = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const EmojiContainer = styled.span`
  cursor: help;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 99;

  & svg {
    height: 30px;
    width: 30px;
  }
`;

const Label = styled.div`
  position: absolute;
  top: 10%;
  width: calc(100% - 40px);
  opacity: 0.4;
  z-index: 999;
  padding: 20px;
  text-transform: uppercase;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: white;
  background-color: #a10300;
`;

export default function Product({
  id,
  slug,
  image = null,
  images = [],
  title,
  description,
  price,
  currency,
  place,
  is_new,
  is_sold,
  archived_at,
}) {
  const [currentImage, setCurrentImage] = useState(
    getDefaultCurrentImage(image, images)
  );

  const handleMouseOver = () => {
    if (images.length > 1) {
      setCurrentImage(images[1]?.url);
    }
  };

  const handleMouseLeave = () => {
    setCurrentImage(getDefaultCurrentImage(image, images));
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    setCurrentImage(getDefaultCurrentImage(image, images));
    // eslint-disable-next-line
  }, [id]);

  return (
    <Column
      to={`/post/${slug}/${id}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className={is_sold || archived_at !== null ? "is-pale" : ""}
    >
      {is_new ? (
        <EmojiContainer data-tip="Bu yeni bir ürün.">
          <SparkleIcon />
        </EmojiContainer>
      ) : (
        ""
      )}

      {is_sold && archived_at === null ? (
        <Label className="label">SATILDI</Label>
      ) : (
        ""
      )}
      {archived_at ? (
        <Label className="label" style={{ backgroundColor: "#cc7e00" }}>
          Arşiv
        </Label>
      ) : (
        ""
      )}

      <ImageContainer>
        <MainImage src={currentImage} alt={title} height={200} width={388} />
      </ImageContainer>
      <Content>
        <ProductTitle>{title}</ProductTitle>
        <ProductContent>{description}</ProductContent>
      </Content>

      <PriceRow>
        <Place>{place?.title}</Place>
        <Price value={price} currency={currency} />
      </PriceRow>
    </Column>
  );
}
