import React from "react";
import styled from "styled-components";
import LinkButton from "../Button";

const Container = styled.div`
  padding: 50px 0px;
  margin-bottom: 100px;
`;

const Code = styled.h1`
  color: #e04f1a;
  margin: 0px;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Title = styled.h2`
  margin: 10px 0px;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Description = styled.p`
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 500;
  color: #666;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const StyledButton = styled(LinkButton)`
  padding: 20px 40px;

  @media only screen and (max-width: 768px) {
    display: block;
    box-sizing: border-box;
    width: 100% !important;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
`;

export default function NotFound() {
  return (
    <>
      <Container>
        <Code>404 Error</Code>
        <Title>Page Not Found</Title>
        <Description>
          We are sorry but the page you are looking for was not found.
        </Description>
        <StyledButton href="/">Back to Home</StyledButton>
      </Container>
    </>
  );
}
