import React from "react";
import SplashSection from "./SplashSection";
import EditorChoiceSection from "./EditorChoiceSection";
import WorkingProcessSection from "./WorkingProcessSection";
import GreenNewsSection from "./GreenNewsSection";
import StatsSection from "./StatsSection";
import TestimonialsSection from "./TestimonialsSection";
import BenefitsSections from "./BenefitsSections";
import AboutUsSection from "./AboutUsSection";
// import SubscriptionSection from "./SubscriptionSection";
import FAQSection from "./FAQSection";

export default function HomePage() {
  return (
    <div>
      <SplashSection />
      <EditorChoiceSection />
      <WorkingProcessSection />
      <GreenNewsSection />
      <StatsSection />
      <TestimonialsSection />
      <BenefitsSections />
      <AboutUsSection />
      {/* <SubscriptionSection /> */}
      <FAQSection />
    </div>
  );
}
