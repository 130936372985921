import { useState } from "react";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";

const Container = styled.div`
  height: 352px;
  width: 500px;
  margin-bottom: 15px;
  text-align: center;
  background: #f9fbfd;
  border: 1px solid #e5e9ed;
  box-shadow: 0px 2px 4px rgba(229, 233, 237, 0.1);
  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const MainImage = styled.img`
  width: 500px;
  height: 352px;
  object-fit: contain;
  cursor: zoom-in;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &.no-image {
    cursor: unset;
    padding: 10px;
    width: 480px;
    height: 380px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: 13px;
  background: #f9fbfd;
  border: 1px solid #e5e9ed;
  box-shadow: 0px 2px 4px rgba(229, 233, 237, 0.1);
  border-radius: 5px;
  padding-left: 10px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Preview = styled.div`
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
  position: relative;
  overflow: hidden;
  background: #f9fbfd;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 60px;
    width: 60px;
    min-height: 60px;
    min-width: 60px;
  }
`;

const SmallImage = styled.img`
  position: absolute;
  top: 0p;
  cursor: pointer;
  transition: opacity 100ms linear;
  width: 80px;
  height: 80px;
  object-fit: contain;

  &:hover {
    opacity: 0.5;
  }
`;

export default function Images({ post }) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [current, setCurrent] = useState(
    post.images.length > 0 ? post.images[0] : null
  );
  const { images } = post;
  const gallery = images.map((i) => i.url);

  const handleOpenGallery = () => {
    const itemIndex = images.findIndex((i) => i.id === current.id);
    setPhotoIndex(itemIndex || 0);
    setIsGalleryOpen(true);
  };

  return (
    <>
      <Container>
        {current && <MainImage src={current.url} onClick={handleOpenGallery} />}
        {!current && <MainImage src="/no-img2.png" className="no-image" />}
      </Container>
      {images.length > 1 && (
        <PreviewContainer>
          {images.map((image, index) => (
            <Preview key={index}>
              <SmallImage src={image.url} onClick={() => setCurrent(image)} />
            </Preview>
          ))}
        </PreviewContainer>
      )}
      {isGalleryOpen && (
        <Lightbox
          animationDuration={180}
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsGalleryOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </>
  );
}
