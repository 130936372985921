import { useEffect } from "react";
import styled from "styled-components";
import Filter from "../../../components/Filter";
import TextInput from "../../../components/Form/TextInput";
import CategorySelect from "../../../components/Form/CategorySelect";
import PlaceSelect from "../../../components/Form/PlaceSelect";
import { debounce } from "lodash";
import { useState } from "react";

const Container = styled.div`
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: block;
    position: absolute;
    width: calc(100% - 40px);
    padding-bottom: 0px;
    border: 1px solid #f1f1f1;

    &.is-open {
      padding: 15px;
      width: calc(100% - 70px);
    }
  }
`;

const Filters = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const ToggleFilterStatus = styled.button`
  border: none;
  display: block;
  width: 100%;
  color: rgb(24, 24, 27);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.is-open {
  }
`;

export default function PostFilter({
  filters,
  handleChange,
  filterStatus,
  handleFilterStatus,
}) {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchChange = debounce((event) => {
    handleChange({ search_text: event.target.value, page: 1 });
  }, 300);

  return (
    <Container className={filterStatus ? "is-open" : ""}>
      {(filterStatus || !isMobile) && (
        <Filters>
          <Filter title="Kategori" style={{ width: "300px" }}>
            <CategorySelect
              value={filters.category_id}
              defaultValue={filters.category_id}
              handleChange={(value) =>
                handleChange({ category_id: value?.id || null })
              }
            />
          </Filter>
          <Filter title="Şehir" style={{ width: "300px" }}>
            <PlaceSelect
              value={filters.place_id}
              handleChange={(value) =>
                handleChange({ place_id: value?.id || null })
              }
            />
          </Filter>
          <Filter title="Arama" style={{ width: "100%" }}>
            <TextInput
              placeholder="iPhone"
              defaultValue={filters.search_text}
              onChange={handleSearchChange}
            />
          </Filter>
        </Filters>
      )}
      {isMobile && (
        <ToggleFilterStatus type="button" onClick={handleFilterStatus}>
          {!filterStatus && "Show filters"}
          {filterStatus && "Hide filters"}
        </ToggleFilterStatus>
      )}
    </Container>
  );
}
