import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import TextInput from "../../components/Form/TextInput";
import SelectBox from "../../components/Form/SelectBox";
import axe from "../../helpers/axe";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/LinkButton";
import { toast } from "react-toastify";
import { setFilters } from "../../store/slices/filters";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  max-width: 500px;
  left: 0px;
  right: 0px;
  margin: auto;
`;

const PageTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

const InputTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export default function ProfileOptions() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    country: null,
    email_subscription: auth?.user?.email_subscription,
  });
  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    const { data } = await axe("places")
      // .select(["id", "title"])
      .where("place_type", "=", "COUNTRY")
      .paginate(1, 300);
    setCountries(data);
    setForm({
      ...form,
      country: data.find((i) => i.id === auth.user.default_country_id),
    });
  };

  const handleSave = async () => {
    try {
      await axios.put("updateOptions", {
        country_id: form.country.id,
        email_subscription: form.email_subscription,
      });
      dispatch(
        setFilters({
          place_id: form.country.id,
        })
      );
      toast.success("Great!");
      navigate("/");
    } catch (error) {
      toast.error(error?.response?.data?.error || "An error occurred");
    }
  };

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line
  }, []);

  if (auth?.isLogged === false) {
    return "";
  }

  return (
    <>
      <Container>
        <PageTitle>Profil ayarları</PageTitle>
        <InputTitle>Kullanıcı Adı</InputTitle>
        <TextInput value={`@${auth.user.login}`} disabled />

        <InputTitle>Ülke *</InputTitle>
        <SelectBox
          value={form.country}
          onChange={(country) => setForm({ ...form, country })}
          options={countries}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.id}
          isSearchable={true}
          isClearable={false}
          placeholder="Ülke seç"
        />
        {/* <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <CheckBox
            label="Güncellemeler için e-posta aboneliği oluşturç"
            checked={form.email_subscription}
            value={form.email_subscription}
            defaultValue={form.email_subscription}
            onChange={(event) =>
              setForm({ ...form, email_subscription: event.target.checked })
            }
          />
        </div> */}
        <div style={{ marginTop: "10px" }}>
          <Button block onClick={handleSave} disabled={!form.country}>
            Kaydet
          </Button>
        </div>
      </Container>
    </>
  );
}
