import { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Images from "./Images";
import Contact from "./Contact";
import {
  PlaceIcon,
  CategoryIcon,
  // HeartIcon,
  // WarningIcon,
  CheckIcon,
  EditIcon,
  PublishIcon,
} from "../../../components/Icons";
import Price from "../../../components/Price";
import Button from "../../../components/Button";
import { useEffect } from "react";
import axe from "../../../helpers/axe";
import Product from "../../../components/Product";
import Modal from "../../../components/Modal";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const LeftBox = styled.div``;
const RightBox = styled.div`
  width: 100%;
`;

const BlueBox = styled.div`
  background: #f9fbfd;
  border: 1px solid #e5e9ed;
  box-shadow: 0px 2px 4px rgba(229, 233, 237, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #253745;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ActionBox = styled(BlueBox)`
  display: flex;
  gap: 5px;
  align-items: center;
  min-height: 40px;

  @media only screen and (max-width: 768px) {
    display: block;
    text-align: center;

    & div.view-count {
      margin-top: 20px;
    }
  }

  & div.view-count {
    font-size: 14px;
    font-weight: 600;
    text-align: ${({ isMyPost }) => (isMyPost ? "right" : "center")};
    flex-grow: 2;
  }
`;

const DataLine = styled.div`
  display: flex;
  margin-bottom: 5px;
  gap: 5px;
  align-items: center;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const DataIcon = styled.div`
  & svg {
    height: 32px;
    width: 32px;
  }
`;

const DateText = styled.div``;

const PriceBox = styled(BlueBox)`
  font-weight: 700;
  font-size: 22px;
  color: #253745;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Green = styled.span`
  color: #247c66;
`;

const ActionButton = styled.button`
  background: #f9fbfd;
  border: 1px solid #e5e9ed;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  color: #253745;
  transition: all 300ms ease-in-out;

  &:hover {
    background: #e5e9ed;
    color: #247c66;
  }

  &:disabled {
    color: #aaa;
    &:hover {
      color: #aaa;
      background: #f9fbfd;
    }
  }

  & svg {
    margin-top: 4px;
  }
`;

const ContentTitle = styled.h5`
  font-family: "Inter";
  font-style: normal;
  margin: 0px 0px 5px 0px;
  font-size: 18px;
  font-weight: 600;
  color: #253745;
`;

const Content = styled(BlueBox)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: rgba(37, 55, 69, 0.7);
`;

const SimilarTitle = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #253745;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
`;

const SimilarPosts = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export default function Body({ post, refetch }) {
  const navigate = useNavigate();
  const [similars, setSmilars] = useState([]);
  const [modal, setModal] = useState(null);
  const auth = useSelector((state) => state.auth);

  const handleMarkAsSold = async () => {
    try {
      await axios.patch(`posts/${post.id}`, { is_sold: true });
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: "mark_ad_sold",
      //     count: 1,
      //   },
      // ]);
      navigate("/my-posts");
      toast.success("Harika! İlandaki ürünü sattığınız için mutluyuz.");
      setModal(null);
    } catch (error) {
      toast.error("Bir hata meydana geldi.");
    }
  };

  const handlePublish = async () => {
    try {
      await axios.patch(`posts/${post.id}`, {
        archived_at: null,
        published_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      });
      toast.success("Harika! İlanı yeniden yayına aldık.");
      refetch();
      setModal(null);
    } catch (error) {
      toast.error("Bir hata meydana geldi.");
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    const getPosts = async () => {
      const query = axe("posts");

      const { data } = await query
        .with(
          "place{title},user{github_name|github_username|github_avatar_url},images"
        )
        .where("category_id", post.category_id)
        .whereNot("id", post.id)
        .sort("-created_at")
        .paginate(1, 3);
      setSmilars(data);
    };

    getPosts();
    // eslint-disable-next-line
  }, []);

  const openMarkAsSoldQuestion = () => {
    setModal({
      question: "İlanı satıldı olarak işaretlemek istiyor musun?",
      footer: <Button onClick={handleMarkAsSold}>Evet, devam et!</Button>,
    });
  };

  const openRepublishQuestion = () => {
    setModal({
      question: "İlanı tekrardan yayına almak istediğine emin misin?",
      footer: <Button onClick={handlePublish}>Evet, yayına al!</Button>,
    });
  };

  const isMyPost =
    auth.isLogged && auth?.user?.id === post?.user?.id && !post.is_sold;

  return (
    <>
      <Container>
        <LeftBox>
          <Images post={post} />
        </LeftBox>
        <RightBox>
          <BlueBox>
            <Contact post={post} />
          </BlueBox>
          <BlueBox>
            <DataLine>
              <DataIcon>
                <PlaceIcon />
              </DataIcon>
              <DateText>{post?.place?.title}</DateText>
            </DataLine>
            <DataLine>
              <DataIcon>
                <CategoryIcon />
              </DataIcon>
              <DateText>{post?.category?.tr_name}</DateText>
            </DataLine>
          </BlueBox>
          <PriceBox>
            Fıyat:{" "}
            <Green>
              <Price value={post.price} currency={post.currency} />
            </Green>
          </PriceBox>
          <ActionBox isMyPost={isMyPost}>
            {isMyPost && (
              <>
                {/* <ActionButton type="button" data-tip="Add to favorites">
                      <HeartIcon />
                    </ActionButton>
                    <ActionButton type="button" data-tip="Report">
                      <WarningIcon />
                    </ActionButton> */}
                {!post.is_sold ? (
                  <>
                    <ActionButton
                      type="button"
                      data-tip="Satıldı"
                      onClick={openMarkAsSoldQuestion}
                    >
                      <CheckIcon />
                    </ActionButton>
                    {post.archived_at !== null ? (
                      <ActionButton
                        type="button"
                        data-tip="Tekrar yayına al"
                        onClick={openRepublishQuestion}
                      >
                        <PublishIcon />
                      </ActionButton>
                    ) : (
                      ""
                    )}
                    <ActionButton
                      type="button"
                      data-tip="Düzenle"
                      onClick={() => navigate(`/new-post/${post.id}`)}
                    >
                      <EditIcon />
                    </ActionButton>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            <div className="view-count">
              Bu ilan {post.view_count} kez görüntülendi.
            </div>
          </ActionBox>
        </RightBox>
      </Container>
      <Content>
        <ContentTitle>Detaylar</ContentTitle>
        {post.description}
      </Content>
      {similars.length > 0 && (
        <>
          <SimilarTitle>
            Benzer <Green>ilanlar</Green>...
          </SimilarTitle>
          <SimilarPosts>
            {similars &&
              similars.map((item, index) => <Product key={index} {...item} />)}
          </SimilarPosts>
        </>
      )}
      {modal && (
        <Modal
          title="Emin misin?"
          footer={modal.footer}
          onClose={() => setModal(null)}
        >
          {modal.question}
        </Modal>
      )}
    </>
  );
}
