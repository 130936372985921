import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import axe from "../../../helpers/axe";
import Body from "./Body";
import NotFound from "../../../components/NotFound";
import { SparkleIcon } from "../../../components/Icons";

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #253745;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
  align-items: center;
`;

const EmojiContainer = styled.span`
  cursor: help;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

const Label = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: white;
  background-color: #a10300;
  padding: 20px;
  opacity: 0.62;
  border-radius: 2px;
`;

export default function PostPage() {
  const auth = useSelector((state) => state.auth);
  const params = useParams();
  const [post, setPost] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const fetchPost = async () => {
    setPost(null);
    try {
      const data = await axe(`posts/${params.id}`)
        .with(
          "place{title},user{github_name|github_username|github_avatar_url},images,category"
        )
        .first();
      setPost(data);
    } catch (error) {
      if (error?.response?.status === 404) {
        setNotFound(true);
      } else {
        toast.error("An error occurred!");
      }
    }
  };

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line
  }, [params.id, auth.isLogged]);

  if (notFound) return <NotFound />;
  if (!post) return "";

  const { is_sold, archived_at } = post;

  return (
    <>
      {is_sold && archived_at === null ? <Label>SATILDI</Label> : ""}
      {archived_at !== null ? (
        <Label style={{ backgroundColor: "#cc7e00" }}>Arşiv</Label>
      ) : (
        ""
      )}

      <Title>
        {post.is_new ? (
          <EmojiContainer data-tip="Bu yeni bir ürün.">
            <SparkleIcon />
          </EmojiContainer>
        ) : (
          ""
        )}
        {post.title}
      </Title>
      <Body post={post} refetch={fetchPost} />
    </>
  );
}
