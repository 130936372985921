import Select from "react-select";
import { SELECT_STYLES } from "../styles";

export default function SelectBox({ width = "100%", ...props }) {
  return (
    <Select
      styles={{
        ...SELECT_STYLES,
        width,
      }}
      {...props}
    />
  );
}
