import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const Container = styled.div`
  animation: 300ms ease-out 0s 1 ${fadeIn};
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 500px;
  height: fit-content;
  background: #f6f6f6;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 100px;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

const GrayBox = styled.div`
  background: black;
  opacity: 0.4;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid #999;
  position: relative;
  font-size: 16px;
  font-weight: 500;
`;

const CloseButton = styled.button`
  right: 20px;
  position: absolute;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const Body = styled.div`
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

const Footer = styled.div`
  padding: 20px 20px;
  font-size: 14px;
  font-weight: 400;
`;

export default function Modal({ title, children, footer = null, onClose }) {
  return (
    <>
      <GrayBox />
      <Container>
        <Header>
          {title}
          <CloseButton type="button" onClick={onClose}>
            X
          </CloseButton>
        </Header>
        <Body>{children}</Body>
        {footer && <Footer>{footer}</Footer>}
      </Container>
    </>
  );
}
