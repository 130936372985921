const getLocale = () => {
  return navigator.languages &&
    Array.isArray(navigator.languages) &&
    navigator.languages.length > 0
    ? navigator.languages[0]
    : "en-US";
};

export default function Price({ currency = "TRY", value = 0 }) {
  return (
    <>
      {new Intl.NumberFormat(getLocale(), {
        style: "currency",
        currency,
        currencyDisplay: "narrowSymbol",
      }).format(value)}
    </>
  );
}
