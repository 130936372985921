import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import splashPNG from "./splash.png";

const Container = styled.div`
  padding: 50px 0px;
  display: flex;
  gap: 30px;

  @media only screen and (max-width: 768px) {
    padding: 10px 0px;
    flex-direction: column;
  }
`;

const H1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48x;
  color: #253745;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin: 10px 0px 30px 0px;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 38px;
  }
`;

const Description = styled.p`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: rgba(37, 55, 69, 0.7);
  margin: 0px 0px 50px 0px;
`;

const Green = styled.span`
  color: #247c66;
`;

const CallToAction = styled(Link)`
  background: #247c66;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  padding: 14px 30px;
  color: #f9fbfd;
  font-family: "Nunito";
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
  }
`;

const PngContent = styled.div`
  @media only screen and (max-width: 768px) {
    & img {
      width: 100%;
    }
  }
`;

const LineBreak = styled.br`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default function SplashSection() {
  return (
    <Container>
      <PngContent>
        <img src={splashPNG} alt="ikinciel.dev" />
      </PngContent>
      <div>
        <H1>
          GitHub hesabı olan geliştiricilerin <Green>ikinci el</Green> <br />{" "}
          alışveriş adresi
        </H1>
        <Description>
          Buradaki tüm ilanlar sadece aktif ve doğrulanmış <LineBreak />
          <Green>GitHub</Green> hesabına sahip olan kullanıcılar tarafından
          görüntülenebilir. Sen de ilanları incelemeye başlayarak{" "}
          <Green>güvenilir</Green> bir alışveriş deneyimini hemen
          yaşayabilirsin.
        </Description>
        <CallToAction to="/">İlanları İncele</CallToAction>
      </div>
    </Container>
  );
}
