import SelectBox from "../SelectBox";

export const POST_TYPES = [
  {
    value: false,
    title: "İkinci el",
  },
  {
    value: true,
    title: "Sıfır",
  },
];

export default function PostTypeSelect({
  width,
  defaultValue,
  value,
  handleChange,
  ...props
}) {
  return (
    <SelectBox
      value={value ? POST_TYPES.find((i) => i.value === value?.value) : null}
      defaultValue={POST_TYPES.find((i) => i.value === defaultValue)}
      onChange={handleChange}
      options={POST_TYPES}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.value}
      isSearchable={true}
      isClearable={true}
      width={width}
      {...props}
    />
  );
}
