import React, { useEffect, useState } from "react";
import styled from "styled-components";
import people1 from "./people/01.jpg";
import people2 from "./people/02.jpg";
import blush from "./blush.svg";

const ITEMS = [
  {
    id: 1,
    person: "Kadir Furkan Kiraz",
    title: "Indie Maker",
    link: "https://twitter.com/hellokfk",
    content:
      "This is a powerful platform that every developer must know when any device is needed to buy. I can find the device I would like to buy and contact the owner easily. I don't lose any time while researching whether the device is suitable for my tech stack or not since the people I have contacted are developers.",
    image: people1,
  },
  {
    id: 2,
    person: "Fatih Kadir Akın",
    title: "JavaScript Developer",
    link: "https://twitter.com/fkadev",
    content:
      "I haven't submitted on ikinciel.dev yet, but I'm looking constantly through many submissions and this let me learn the average prices of the computers, tools, etc. I own. It's very useful even if you don't use the service completely.",
    image: people2,
  },
];

const Container = styled.div`
  padding: 120px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const LeftSide = styled.div`
  max-width: 600px;
`;

const Content = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #253745;
  position: relative;
`;

const Quote = styled.span`
  font-family: "Nunito";
  font-style: italic;
  position: absolute;
  left: -40px;
  top: 30px;
  font-weight: 500;
  font-size: 150px;
  line-height: 40px;
  color: rgba(36, 124, 102, 0.2);
`;

const Person = styled.a`
  font-weight: 700;
  font-size: 24px;
  color: #253745;
  border-bottom: 4px solid #253745;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #444;
  }
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 21px;
  color: #253745;

  @media only screen and (max-width: 768px) {
    line-height: 36px;
  }
`;

const ProfilePicture = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-right: -80px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const BlushContainer = styled.img`
  position: absolute;
  top: 20px;
  right: 100px;
  z-index: -999;
`;

const ImageBox = styled.img`
  width: 260px;
  height: 340px;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
`;

const Controllers = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 30px;
`;

const Bullet = styled.button`
  background: rgba(37, 55, 69, 0.7);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  height: 14px;
  width: 14px;
  border: none;
  cursor: pointer;

  &.active {
    background-color: #253745;
  }
`;

export default function TestimonialsSection() {
  const [current, setCurrent] = useState(ITEMS[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (current.id < ITEMS.length) {
        setCurrent(ITEMS[current.id]);
      } else {
        setCurrent(ITEMS[0]);
      }
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <Container>
      <LeftSide>
        <Content>
          {current.content}
          <Quote>"</Quote>
        </Content>
        <div>
          <Person href={current.link} target="_blank">
            {current.person}
          </Person>
          <Title>, {current.title}</Title>
        </div>
        <Controllers>
          {ITEMS.map((item, index) => (
            <Bullet
              className={item.person === current.person ? "active" : ""}
              key={index}
              type="button"
              onClick={() => setCurrent(item)}
            />
          ))}
        </Controllers>
      </LeftSide>
      <ProfilePicture>
        <ImageBox src={current.image} alt={current.person} />
        <BlushContainer src={blush} />
      </ProfilePicture>
    </Container>
  );
}
