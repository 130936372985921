import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export default styled.div`
  border: 4px solid #ddd;
  border-top: 4px solid #555;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${rotate} 700ms linear infinite;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
`;
