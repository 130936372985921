import styled from "styled-components";

const Container = styled.div`
  width: 180px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 6px;
  }
`;

const Title = styled.div`
  color: rgb(24, 24, 27);
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
`;

const ComponentBox = styled.div``;

export default function Filter({ title, children, ...props }) {
  return (
    <Container {...props}>
      <Title>{title}</Title>
      <ComponentBox>{children}</ComponentBox>
    </Container>
  );
}
