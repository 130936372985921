import styled from "styled-components";
import axios from "axios";
import TextInput from "../../../components/Form/TextInput";
import CategorySelect from "../../../components/Form/CategorySelect";
import PlaceSelect from "../../../components/Form/PlaceSelect";
import Textarea from "../../../components/Form/Textarea";
import CheckBox from "../../../components/Form/CheckBox";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploader from "../../../components/ImageUploader";
import axe from "../../../helpers/axe";
import MoneyInput from "../../../components/Form/MoneyInput";
import PostTypeSelect, {
  POST_TYPES,
} from "../../../components/Form/PostTypeSelect";
import { CURRENCIES } from "../../../constants";

const Container = styled.div`
  max-width: 500px;
  margin: auto;
  left: 0px;
  right: 0px;
`;

const PageTitle = styled.h1`
  color: rgb(24, 24, 27);
  font-size: 24px;
  font-weight: 600;
`;

const Description = styled.p`
  color: rgb(24, 24, 27);
  font-size: 14px;
  font-weight: 300;
`;

const Form = styled.form``;

const Label = styled.div`
  color: rgb(24, 24, 27);
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
  padding-top: 10px;
`;

const ButtonContainer = styled.div`
  padding: 20px 0px;
`;

const DEFAULT_FORM_VALUES = {
  is_new: POST_TYPES.find((i) => i.value === false),
  title: "",
  price: "",
  currency: CURRENCIES.find((i) => i.code === "EUR"),
  category: null,
  place: null,
  contact_info: "",
  description: "",
  images: [],
  is_confirmed: false,
};

export default function NewPostPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [form, setForm] = useState({
    id: params?.id || null,
    ...DEFAULT_FORM_VALUES,
  });

  useEffect(() => {
    if (params?.id) {
      setForm({
        ...form,
        id: params?.id || null,
      });
    } else {
      setForm({
        id: null,
        ...DEFAULT_FORM_VALUES,
      });
    }
    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    const fetchPost = async () => {
      const item = await axe(`posts/${form.id}`)
        .with("images,category,place")
        .first();
      setForm({
        ...form,
        is_new: POST_TYPES.find(
          (i) => i.value === (item.is_new === 1) || false
        ),
        title: item.title,
        price: item.price,
        currency: CURRENCIES.find((i) => i.code === item.currency),
        description: item.description,
        contact_info: item.contact_info,
        images: item.images.map((i) => i.url),
        category: item.category.id,
        place: item.place?.id,
      });
    };

    if (form.id) {
      fetchPost();
    }
    // eslint-disable-next-line
  }, [form.id]);

  const handleSave = async () => {
    let validation = new Validator(form, {
      is_new: "required",
      title: "required",
      price: "required|numeric",
      currency: "required",
      category: "required",
      place: "required",
      contact_info: "required",
      description: "required",
      is_confirmed: "required|boolean",
    });
    const isValid = validation.passes() && form.is_confirmed;

    if (!isValid) {
      return toast.error("Tüm zorunlu alanlar doldurulmalıdır.");
    }

    const method = form.id ? "patch" : "post";
    const url = form.id ? `posts/${form.id}` : "posts";
    // const eventName = form.id ? "ad_update" : "ad_create";

    try {
      const { data } = await axios[method](url, {
        is_new: form.is_new?.value || false,
        title: form.title,
        price: parseInt(form.price, 10),
        currency: form.currency.code,
        category_id: form.category.id,
        place_id: form.place,
        contact_info: form.contact_info,
        description: form.description,
        images: form.images,
      });
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: eventName,
      //     count: 1,
      //     segmentation: {
      //       succeed: true,
      //     },
      //   },
      // ]);

      navigate(`/post/${data.slug}/${data.id}`);
    } catch (error) {
      // Countly.q.push([
      //   "add_event",
      //   {
      //     key: eventName,
      //     count: 1,
      //     segmentation: {
      //       succeed: false,
      //     },
      //   },
      // ]);
      toast.error("Bir hata meydana geldi.");
    }
  };

  return (
    <>
      <Container>
        <PageTitle>
          {form.id && "Düzenle"}
          {!form.id && "Yeni ilan"}
        </PageTitle>
        <Description>
          {form.id && "İlanı düzenleyebilirsiniz."}
          {!form.id && "Formu doldurarak yeni bir ilan yayınlayabilirsiniz."}
        </Description>
        <Form>
          <Label>İlan türü *</Label>
          <PostTypeSelect
            value={form.is_new}
            defaultValue={form.is_new}
            onChange={(value) => setForm({ ...form, is_new: value })}
            width="100%"
            isDisabled={isUploading}
            isClearable={false}
          />
          <Label>Başlık *</Label>
          <TextInput
            value={form.title}
            onChange={(event) =>
              setForm({ ...form, title: event.target.value })
            }
            placeholder="Buraya ilgi çekici bir başlık yazabilirsiniz"
            disabled={isUploading}
          />
          <Label>Fiyat *</Label>
          <MoneyInput
            placeholder="Adil bir fiyat girmeniz satışı hızlandırır"
            price={form.price}
            currency={form.currency}
            onChange={(key, value) =>
              setForm({
                ...form,
                [key]: value,
              })
            }
            disabled={isUploading}
          />
          <Label>Kategori *</Label>
          <CategorySelect
            value={form.category}
            onChange={(value) => setForm({ ...form, category: value })}
            width="100%"
            isDisabled={isUploading}
          />
          <Label>Şehir *</Label>
          <PlaceSelect
            value={form.place}
            onChange={(value) => setForm({ ...form, place: value?.id || null })}
            width="100%"
            isDisabled={isUploading}
          />
          <Label>İletişim bilgisi *</Label>
          <TextInput
            value={form.contact_info}
            onChange={(event) =>
              setForm({ ...form, contact_info: event.target.value })
            }
            placeholder="Telefon, e-posta, ya da Discord kullanıcı adınız"
            disabled={isUploading}
          />
          <Label>Açıklama *</Label>
          <Textarea
            rows="5"
            value={form.description}
            onChange={(event) =>
              setForm({ ...form, description: event.target.value })
            }
            placeholder="Ürününün öne çıkan özelliklerini burada belirtebilirsiniz."
            disabled={isUploading}
          ></Textarea>
          <Label>Görseller * (maks 5)</Label>
          <ImageUploader
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            images={form.images}
            setImages={(images) => setForm({ ...form, images })}
            multiple={true}
            accept="image/png, image/jpeg"
            max={5}
          />
          <Description>
            Resimler yalnızca .jpeg ya da .png dosyaları olabilir. Maksimum
            dosya boyutu 2 MB.
          </Description>
          <CheckBox
            label="Girdiğim tüm bilgilerin doğru olduğunu ve kullanım şartlarını onaylıyorum."
            value={form.is_confirmed}
            onChange={(event) =>
              setForm({ ...form, is_confirmed: event.target.checked })
            }
            disabled={isUploading}
          />
          <ButtonContainer>
            <Button block={true} onClick={handleSave}>
              Yayınla
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    </>
  );
}
